import { Query } from "@angular/core";

export class QueryObject {
    isSortAsc: boolean;
    sortBy: string;
    pageCount: number;
    page: number;
    pageSize: number;
    printall:boolean;
}

export class StoreQueryObject extends QueryObject{
    searchString: string;
    provinceId: number;
    cityId:number;
    status:number;
    printall:boolean;
}

export class SCVQueryObject extends QueryObject{
    searchString: string;
    isActive: string;
    fromAmount:number;
    toAmount:number;
    variationType:number;
}
export class OfferDetailsQueryObject extends QueryObject{
    searchString:string;
    discountType:number;
    offerMethod:number;
    startDate_From:Date;
    startDate_To:Date;
    endDate_From:Date;
    endDate_To:Date;
    startDateEnumSelectedOption:number;
    endDateEnumSelectedOption:number;
    selectedOption:string;
    printall:boolean;
}
export class OrderQueryObject extends QueryObject{
    searchString: string;
    deliveryMethod: number;
    status:number;
    deliveryDate_From:Date;
    deliveryDate_To:Date;
    deliveryDateEnumSelectedOption:number;
    isrunningOrders:boolean;
}
export class EmailTemplateQueryObject extends QueryObject{
  searchString:string;
  active:number;
  emailTemplateId:number;
  printall:boolean;
}
export class RoleQueryObject extends QueryObject{
  searchString:string;

}
export class RewardTypeQueryObject extends QueryObject{
  searchString:string;
  printall:boolean;
}
export class CountryQueryObject extends QueryObject{
  searchString:string;
  printall:boolean;
}

export class ProgramQueryObject extends QueryObject{
  searchString:string;
  printall:boolean;
  isSortAsc: boolean;
  sortBy: string;
  page: number;
  pageSize: number;
}
export class RegistedBranchQueryObject extends QueryObject{
  searchString:string;
  printall:boolean;
  isSortAsc: boolean;
  sortBy: string;
  page: number;
  pageSize: number;
}

export class PradeshSabhaQueryObject extends QueryObject{
  searchString:string;
  printall:boolean;
  isSortAsc: boolean;
  sortBy: string;
  page: number;
  pageSize: number;
  countryId: number=0;
  provinceId: number=0;
  districtId: number=0;
}

export class MemberQueryObject extends QueryObject{
  searchString:string;
  isSortAsc: boolean;
  sortBy: string;
  pageCount: number;
  page: number;
  pageSize: number;
  isSchool: boolean = false;
  printall:boolean;
  countryId: number=0;
  provinceId: number=0;
  districtId: number=0;
  pratinidhiSabhaId: number=0;
  pradeshSabhaId: number=0;
  chetraId: number=0;
}
export class DoctorQueryObject extends QueryObject{
  searchString:string;
  isSortAsc: boolean;
  sortBy: string;
  qualification: string;
  pageCount: number;
  page: number;
  pageSize: number;
  printall:boolean;
  countryId: number=0;
  provinceId: number=0;
  registedOnBranchId: number=0;
  districtId: number=0;
  
  pratinidhiSabhaId: number=0;
  pradeshSabhaId: number=0;
  chetraId: number=0;
}
export class DoctorWorkHistroyQueryObject extends QueryObject{
  searchString:string;
  doctorId: number;
  isSortAsc: boolean;
  sortBy: string;
  pageCount: number;
  page: number;
  pageSize: number;
  printall:boolean;
}
export class ProvinceQueryObject extends QueryObject{
  searchString:string;
  printall:boolean;
  countryId: number=0;

}
export class DistrictQueryObject extends QueryObject{
  searchString:string;
  printall:boolean;
  countryId: number=0;
  pageSize: number;
  provinceId: number=0;
}
export class WareHouseQueryObject extends QueryObject{
  searchString:string;
  printall:boolean;
}
export class DataPermissionQueryObject extends QueryObject{
  searchString:string;
}
export class SystemPermissionQueryObject extends QueryObject{
  searchString:string;
  active:number;
  id:number;
  printall:boolean;
}
export class UserDetailsQueryObject extends QueryObject{
    filtertext: string;
    roleId: number;
    storeId: number;
    userStatusId: number;
    printall:boolean;
}
export class EmailQueueQueryObject extends QueryObject{
  searchString:string;
  statusId:number;
  cancelled:number;
  startDate:Date;
  endDate:Date;
  selectedOption:string;
  queuedOn:string;
  sentOn:string;
  queuedStartDate:Date;
  queuedEndDate:Date;
  sentOnStartDate:Date;
  sentOnEndDate:Date;
  printall:boolean;

}
export class EmailLogQueryObject extends QueryObject{
  searchString:string;
  isSent:number;
  startDate:Date;
  endDate:Date;
  selectedOption:string;
  printall:boolean;
}
export class StatusSearchQueryObject extends QueryObject{
    status:number;
    searchString:string;
    sentOn:string;
    printall:boolean;
}
export class SearchQueryObject extends QueryObject{
    searchString:string;
    printall:boolean;
}
export class CityListSearchQueryObject{
  searchString:string;
  isSortAsc: boolean;
  sortBy: string;
  pageCount: number;
  page: number;
  pageSize: number;
  printall:boolean;
  countryId: number=0;
  provinceId: number=0;
  districtId: number=0;
  municipalitiesId:number=0;
}
export class MuncipalityQueryObject extends QueryObject{
    searchString:string;
    isSortAsc: boolean;
    sortBy: string;
    pageCount: number;
    page: number;
    pageSize: number;
    printall:boolean;
    countryId: number=0;
    provinceId: number=0;
    districtId: number=0;
}
export class PratinidhiQueryObject extends QueryObject{
  searchString:string;
  isSortAsc: boolean;
  sortBy: string;
  pageCount: number;
  page: number;
  pageSize: number;
  printall:boolean;
  countryId: number=0;
  provinceId: number=0;
  districtId: number=0;
}

export class ChetraQueryObject extends QueryObject{
  searchString:string;
  isSortAsc: boolean;
  sortBy: string;
  pageCount: number;
  page: number;
  pageSize: number;
  printall:boolean;
  pradeshSabhaId: number=0;
}
export class TypeQueryObject extends QueryObject{
    type:number;
    searchString:string;
}
export class MasterPropertyQueryObject extends QueryObject{
    status:number;
    type:number;
    searchString:string;
}
export class CarouselQueryObject extends QueryObject{
    type:number;
    searchString:string;
}
export class mlvQueryObject extends QueryObject{
    masterListId:number;
    status:number;
    searchString:string;
}
export class NewsletterSubscriberQueryObject {
    isSortAsc: boolean;
    sortBy: string;
    pageCount: number;
    page: number;
    pageSize: number;
    searchText: string;
    status: number;
    printall:boolean;
}

export class QueryObjectRatingReview {
    isSortAsc: boolean;
    sortBy: string;
    pageCount: number;
    page: number;
    pageSize: number;
    rating:number;
    status:number;
    searchString:string;
    productId:number;
    printall:boolean;

}

export class QueryObjectProduct {
    isSortAsc: boolean;
    sortBy: string;
    pageCount: number;
    page: number;
    pageSize: number;
    categoryId:number;
    storeId:number;
    searchString:string;
    printall:boolean;
    productId: number;
    productStoreStatusId : number;
}

export class QueryObjectActionHistory {
    isSortAsc: boolean;
    sortBy: string;
    pageCount: number;
    page: number;
    pageSize: number;
    code: string;
    codeId: number;
}
