<!-- Container-fluid Ends-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card tab2-card">
        <div class="card-header">
          <h5> {{formStatus}} Doctor</h5>
        </div>
        <div class="card-body tab2-card">
          <ngb-tabset class="tab-coupon">
            <ngb-tab title="{{formStatus}} Doctor">
              <ng-template ngbTabContent>
                <div class="tab-pane fade active show" id="account" role="tabpanel" aria-labelledby="account-tab">
                  <form [formGroup]="doctorListForm">
                    <div class="container-fluid">
                      <div class="card">
                        <div class=" card-body tab2-card">
                          <h3>General Information</h3>
                          <hr>
                          <div class="form-group row mr-0">
                            <div class="col-md-6 col-sm-12">
                              <label for="firstName"> First Name</label>
                              <input class="form-control " formControlName="firstName" id="firstName" type="text"
                                required />
                              <span class="text-danger ml-1"
                                *ngIf="(formControls.firstName.touched) && formControls.firstName.errors?.required">
                                First Name Required!!
                              </span>
                            </div>
                            <div class="col-md-6 col-sm-12 mt-md-0 mt-sm-2">
                              <label for="lastName">Last Name</label>
                              <input class="form-control " formControlName="lastName" id="lastName" type="text"
                                required="" />
                              <span class="text-danger ml-1"
                                *ngIf="(formControls.lastName.touched) && formControls.lastName.errors?.required">
                                Last Name Required!!
                              </span>
                            </div>
                          </div>


                          <div class="form-group row mr-0">
                            <div class="col-md-6 col-sm-12 ">
                              <label for="dateOfBirth">Date of Birth</label>
                              <input class="form-control " formControlName="dateOfBirth" id="dateOfBirth" type="date" />
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <label for="financialScheme">Gender</label>
                              <select class="custom-select form-control " formControlName="sex" id="sex">
                                <option value='' disabled>Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>

                              </select>
                            </div>
                            <!-- <div class="col-md-6 col-sm-12 d-flex align-items-center mt-2 ">
                              <label for="sex" class="mt-2">Sex</label>
                              <div class="form-check form-check-inline ml-3">
                                <input class="form-check-input" type="radio" id="male" formControlName="sex"
                                  value="male" />
                                <label class="form-check-label" for="male">Male</label>
                                <div class="form-check form-check-inline ml-3">
                                  <input class="form-check-input" type="radio" formControlName="sex" value="female"
                                    id="female" />
                                  <label class="form-check-label" for="female">Female</label>
                                </div>
                              </div>


                            </div> -->


                          </div>

                          <div class="form-group row mr-0">
                            <div class="col-md-6 col-sm-12">
                              <label for="mobile">Mobile</label>
                              <input class="form-control " formControlName="mobile" id="mobile" maxlength="13"
                                type="number" />
                              <span class="error"
                                *ngIf="formSubmitted && doctorListForm.get('mobile').hasError('maxLength')">
                                Invalid Mobile No!!
                              </span>
                              <span class="text-danger ml-1"
                                *ngIf="(formControls.mobile.touched) && formControls.mobile.errors?.required">
                                Mobile Required!!
                              </span>
                            </div>

                            <div class="col-md-6 col-sm-12 ">
                              <label for="phone">Phone
                              </label>
                              <input class="form-control " formControlName="phone" id="phone" maxlength="13"
                                type="number" />

                              <span class="text-danger ml-1"
                                *ngIf="(formControls.phone.touched) && formControls.phone.errors?.maxLength">
                                Invalid Phone No!!
                              </span>



                            </div>


                          </div>

                          <div class="form-group row mr-0">
                            <div class="col-md-6 col-sm-12">
                              <label for="email">Email
                              </label>
                              <input class="form-control " formControlName="email" id="email" type="email" />

                              <span class="text-danger ml-1"
                                *ngIf="formControls.email.touched && formControls.email.errors?.email">
                                Invalid Email Format!!

                              </span>


                            </div>
                            <div class="col-md-6 col-sm-12 mt-md-0 mt-sm-2">
                              <label for="bloodGroup">Blood Group
                              </label>
                              <input class="form-control " formControlName="bloodGroup" id="bloodGroup" type="text" />
                            </div>
                          </div>

                          <div class="form-group row">
                            <div class="col-md-12 col-sm-12">
                              <label for="uploadPhoto"> Upload Photo</label>
                              <app-member-pic-upload [imageUrl]="imageUrl" [doctorForm]="true" class=" mr--10 "
                                (onUploadFinished)="uploadFinished($event)"></app-member-pic-upload>

                            </div>

                          </div>
                          <h3 class="mt-5">Registration Details</h3>
                          <hr>

                          <div class="form-group row mr-0">
                            <div class="col-md-6 col-sm-12 mt-md-0 mt-sm-2">
                              <label for="nmcNumber">NMC Number
                              </label>
                              <input class="form-control " formControlName="nmcNumber" id="nmcNumber" type="text" />

                            </div>

                            <div class="col-md-6 col-sm-12 mt-md-0 mt-sm-2">
                              <label for="nmcRegistrationDate">NMC Registration Date</label>
                              <input class="form-control " formControlName="nmcRegistrationDate"
                                id="nmcRegistrationDate" type="date" />
                            </div>


                          </div>
                          <div class="form-group row mr-0">
                            <div class="col-md-6 col-sm-12">
                              <label for="qualification"> Qualification</label>
                              <select placeholder="Select a Qualification" class="custom-select form-control "
                                formControlName="qualification" id="qualification">
                                <option value="" disabled>Select Program</option>
                                <option value="MBBS">MBBS</option>
                                <option value="MD">MD</option>
                                <option value="BDS">BDS</option>
                                <option value="DDM">DDM</option>
                                <option value="DDS">DDS</option>
                                <option value="MDS">MDS</option>
                                <option value="MPH-PhD">MPH-PhD</option>
                              </select>
                            </div>
                            <div class="col-md-6 col-sm-12 mt-md-0 mt-sm-2">
                              <label for="affiliatedUniversity">Affiliated University
                              </label>
                              <input class="form-control " formControlName="affiliatedUniversity"
                                id="affiliatedUniversity" type="text" />

                            </div>

                          </div>

                          <div class="form-group row mr-0">
                            <div class="col-md-6 col-sm-12">
                              <label for="collegeName">College Name
                              </label>
                              <input class="form-control " formControlName="collegeName" id="collegeName" type="text" />

                            </div>
                            <div class="col-md-6 col-sm-12 mt-md-0 mt-sm-2">
                              <label for="passedCountryId"> Passed Country</label>
                              <select (change)="onCountrySelection($event)" placeholder="Select a Country"
                                class="custom-select form-control " formControlName="passedCountryId"
                                id="passedCountryId">
                                <option value="" disabled>Select Country</option>
                                <option *ngFor="let country of countryList; let i = index" [value]="country.id">
                                  {{ country.name }}
                                </option>
                              </select>

                            </div>

                          </div>

                          <div class="form-group row mr-0">
                            <div class="col-md-6 col-sm-12">
                              <label for="passedDate">Passed Date</label>
                              <input class="form-control " formControlName="passedDate" id="passedDate" type="date" />

                            </div>
                            <div class="col-md-6 col-sm-12 mt-md-0 mt-sm-2">
                              <label for="financialScheme"> Financial Scheme</label>
                              <select placeholder="Select a Program" class="custom-select form-control "
                                formControlName="financialScheme" id="financialScheme">
                                <option value="" disabled>Select Financial Scheme</option>
                                <option value="Self">Self</option>
                                <option value="Bond">Bond</option>
                                <option value="Government Scholarship">Government Scholarship</option>
                                <option value="Others">Others</option>
                              </select>

                            </div>
                          </div>


                          <div class="form-group row mr-0">
                            <div class="col-md-6 col-sm-12">
                              <label for="ndaNumber">NDA Number
                              </label>
                              <input class="form-control " formControlName="ndaNumber" id="ndaNumber" type="text" />

                            </div>
                            <div class="col-md-6 col-sm-12  mt-md-0 mt-sm-2">

                              <label for="countryId"> Registed Branch</label>
                              <select placeholder="Select a Registed Branch" class="custom-select form-control "
                                formControlName="registedOnBranchId" id="registedOnBranchId">
                                <option value=null disabled>Select Registed Branch</option>
                                <option *ngFor="let registedOnBranch of registedBranchList; let i = index"
                                  [value]="registedOnBranch.id">
                                  {{ registedOnBranch.name }}
                                </option>
                              </select>


                            </div>




                          </div>
                          <div class="form-group row mr-0">
                            <div class="col-md-6 col-sm-12">
                              <label for="designation">Total Experience
                              </label>
                              <input class="form-control " formControlName="totalExperience" id="totalExperience"
                                type="text" />

                            </div>


                          </div>

                          <h3 class="mt-5">Current Working Details</h3>
                          <hr>
                          <div class="row form-group ">
                            <!-- <div class="col-md-6 col-sm-12 mt-md-0 mt-sm-2">
                              <label for="designation">Designation
                              </label>
                              <input class="form-control " formControlName="designation" id="designation" type="text" />
                            </div> -->
                            <div class="col-md-6 col-sm-12 mt-md-0 mt-sm-2">
                              <label for="designation"> Qualification</label>
                              <select placeholder="Select a Designation" class="custom-select form-control "
                                formControlName="designation" id="designation">
                                <option value="" disabled>Select Designation</option>
                                <option value="Dental Surgeon">Dental Surgeon</option>
                                <option value="Sr.Dental Surgeon">Sr.Dental Surgeon</option>
                                <option value="Lecture">Lecture</option>
                                <option value="Asst.Prof">Asst.Prof</option>
                                <option value="Asso.Prof.">Asso.Prof.</option>
                                <option value="Professor">Professor</option>
                                <option value="Consultant">Consultant</option>
                              </select>
                            </div>


                            <div class="col-md-6 col-sm-12 mt-md-0 mt-sm-2">
                              <label for="job">Hospital/Clinic Name
                              </label>
                              <input class="form-control " formControlName="job" id="job" type="text" />
                            </div>
                          </div>


                          <div class="form-group row mr-0">
                            <div class="col-md-6 col-sm-12">
                              <div><label for="currentHospitalContactNo">Hospital/Clinic Contact Number
                                </label>
                                <input class="form-control " formControlName="currentHospitalContactNo"
                                  id="currentHospitalContactNo" type="text" />
                              </div>
                              <div class="row ml-2 mt-4">
                                <div>
                                  <input class="form-control" formControlName="isPermanent" type="checkbox"
                                    class="checkbox_animated" name="permanent" id="permanent" />
                                  <label for="permanent" class="cursor-pointer"> Permanent </label>
                                </div>

                                <div class="ml-3">
                                  <div>
                                    <input class="form-control" formControlName="isPrivateHospital" type="checkbox"
                                      class="checkbox_animated" name="private" id="private" />
                                    <label for="private" class="cursor-pointer"> Private Hospital </label>
                                  </div>
                                </div>

                              </div>
                            </div>
                            <div class="col-md-6 col-sm-12 ">
                              <label for="currentHospitalAddress">Hospital/Clinic Address
                              </label>

                              <textarea class="form-control " formControlName="currentHospitalAddress"
                                id="currentHospitalAddress description" style="height: 6rem;"></textarea>

                            </div>


                          </div>

                          <div class="row form-group">
                            <div class="col-md-6 col-sm-12 mt-4">

                            </div>
                          </div>
                          <h3 class="mt-5">Address</h3>
                          <hr>

                          <div class="form-group row mr-0">
                            <div class="col-md-6 col-sm-12">

                              <label for="countryId"> Country</label>
                              <select (change)="onCountrySelection($event)" placeholder="Select a Country"
                                class="custom-select form-control " formControlName="countryId" id="countryId">
                                <option value="" disabled>Select Country</option>
                                <option *ngFor="let country of countryList; let i = index" [value]="country.id">
                                  {{ country.name }}
                                </option>
                              </select>
                            </div>
                            <div class="col-md-6 col-sm-12 mt-md-0 mt-sm-2">
                              <label for="provinceId">Province</label>
                              <select (change)="onProvinceSelection($event)" class="custom-select form-control "
                                formControlName="provinceId">
                                <option value="" disabled>Select Province</option>
                                <option *ngFor="let province of provinceList; let i = index" [value]="province.id">
                                  {{ province.name }}
                                </option>
                              </select>
                            </div>


                          </div>

                          <div class="form-group row mr-0">
                            <div class="col-md-6 col-sm-12">
                              <label for="districtId"> District</label>
                              <select (change)="onDistrictSelection($event)" placeholder="Select a District"
                                class="custom-select form-control " formControlName="districtId">
                                <option value="" disabled>Select District</option>
                                <option *ngFor="let district of districtList; let i = index" [value]="district.id">
                                  {{ district.name }}
                                </option>
                              </select>
                            </div>
                            <div class="col-md-6 col-sm-12 mt-md-0 mt-sm-2">
                              <label for="municipalitiesId">Municipality</label>
                              <select class="custom-select form-control " formControlName="municipalitiesId">
                                <option value="" disabled>Select Municipality</option>
                                <option *ngFor="let municipality of municipalityList; let i = index"
                                  [value]="municipality.id">
                                  {{ municipality.name }}
                                </option>
                              </select>
                            </div>


                          </div>

                          <div class="form-group row mr-0">
                            <div class="col-md-6 col-sm-12">
                              <label for="wardNo">Ward Number</label>
                              <input class="form-control " formControlName="wardNo" id="wardNo" type="number" />
                            </div>
                            <div class="col-md-6 col-sm-12 mt-md-0 mt-sm-2">
                              <label for="pratinidhiSabhaId">Pratinidhi Sabha</label>
                              <select class="custom-select form-control " formControlName="pratinidhiSabhaId">
                                <option value="" disabled>Select Pratinidhi Sabha</option>
                                <option *ngFor="let pratinidhi of pratinidhiList; let i = index"
                                  [value]="pratinidhi.id">
                                  {{ pratinidhi.name }}
                                </option>
                              </select>
                            </div>


                          </div>

                          <div class="form-group row mr-0">
                            <div class="col-md-6 col-sm-12">
                              <label for="pradeshSabhaId">Pradesh Sabha</label>
                              <select placeholder="Select a Pradesh Sabha" class="custom-select form-control "
                                formControlName="pradeshSabhaId">
                                <option value="" disabled>Select Pradesh Sabha</option>
                                <option *ngFor="let pradesh of pradeshList; let i = index" [value]="pradesh.id">
                                  {{ pradesh.name }}
                                </option>
                              </select>
                            </div>


                          </div>



                          <div class="row">
                            <div class="col-12 mt-3">
                              <div class="pull-right">
                                <button [routerLink]="['/managedelevery/doctor-list']" type="button"
                                  class="btn btn-primary">
                                  Discard
                                </button>
                                <button type="button" id="btnSave" class="btn btn-success ml-2"
                                  (click)="saveDoctorList()">
                                  {{ btnSave }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </form>
                </div>
              </ng-template>
            </ngb-tab>
            <ngb-tab *ngIf="doctorid > 0">
              <ng-template ngbTabTitle>Work History</ng-template>
              <ng-template ngbTabContent>
                <app-list-doctor-work-history-component [doctorid]="doctorid"></app-list-doctor-work-history-component>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>
        </div>
      </div>
    </div>
  </div>
</div>