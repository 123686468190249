import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MemberQueryObject } from 'src/app/shared/models/queryObject';
import { MemberService } from 'src/app/shared/service/ManageDelivery/member.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { Member } from 'src/app/shared/models/member';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { ProvinceService } from 'src/app/shared/service/ManageDelivery/province.service';
import { CountryService } from 'src/app/shared/service/ManageDelivery/country.service';
import { ProvinceDropDownList } from '../district-list/district-list.component';
import { CountryList } from '../province-list/province-list.component';
import { DistrictService } from 'src/app/shared/service/ManageDelivery/district.service';
import { DistrictDropDown } from '../municipality-list/municipality-list.component';
import { PratinidhiSabhaService } from 'src/app/shared/service/ManageDelivery/pratinidhi-sabha.service';
import { PradeshSabhaService } from 'src/app/shared/service/ManageDelivery/pradesh-sabha.service';

@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss'],
})
export class MemberListComponent implements OnInit {
  queryObject = new MemberQueryObject();
  showFilters: Boolean = false;
  noDataFound: string;
  pageSize = environment.pageSize;
  memberName: any;
  title:any;

  columns = [
    { title: 'Action' },
    { title: 'Id', key: 'Id', isSortable: true },
    { title: 'Program', key: 'Program', isSortable: true },
    { title: 'Full Name', key: 'FullName', isSortable: true },
    { title: 'Sex', key: 'Sex', isSortable: true },
    { title: 'DOB', key: 'DateOfBirth', isSortable: true },
    { title: 'Mobile No.', key: 'Mobile', isSortable: true },
    { title: 'Phone No.', key: 'Phone', isSortable: true },
    { title: 'Job', key: 'Job', isSortable: true },
    { title: 'Country', key: 'CountryName', isSortable: true },
    { title: 'Province', key: 'ProvinceName', isSortable: true },
    { title: 'District', key: 'DistrictName', isSortable: true },
    { title: 'Municipalities', key: 'MunicipalitiesName', isSortable: true },
    { title: 'Pratinidhi', key: 'PratinidhiSabhaName', isSortable: true },
    { title: 'Pradesh', key: 'PradeshSabhaName', isSortable: true },
    { title: 'Ward No.', key: 'WardNo', isSortable: true },
  ];
  public memberList: Member[] = [];
  public provinceList: ProvinceDropDownList[]=[];
  public countryList: CountryList[]=[];
  public districtList: DistrictDropDown[]=[];
  public pratinidhiList: DistrictDropDown[]=[];
  public pradeshList: DistrictDropDown[]=[];

  isSchool:boolean = false;

  constructor(
    private _router: Router,
    private memberService: MemberService,
    private spinningManager: SpinnerManagerService,
    private alertyfy: AlertyfyService,
    private provinceService: ProvinceService,
    private countryService: CountryService,
    private districtService: DistrictService,
    private pratinidhiService:PratinidhiSabhaService,
    private pradeshService:PradeshSabhaService,
    private activatedRoute : ActivatedRoute,
    private router: Router
  ) {
    this.spinningManager.showSpinner("Loading.....");
  }

  ngOnInit(): void {

    const fullUrl = this.router.url;
    if(this.router.url == '/managedelevery/school-list'){
      this.title = 'School List';
      this.isSchool = true;
      this.queryObject.isSchool = true;
    }
    else{
      this.title = 'Member List';
      this.isSchool = false;
    }

    this.noDataFound = environment.noDataFound;
    this.queryObject.page = 1;
    this.queryObject.pageSize = 8;
    // this.getProvinces();
    this.getAllCountries();
    // this.getMember();
    this.Reset();

  }

  routeMember(item){
    if(this.isSchool == true) this.router.navigate(['/managedelevery/create-school', item.id]);
    else this.router.navigate(['/managedelevery/create-member', item.id]);
    
  }

  getMember() {
    this.spinningManager.showSpinner('Loading.....');
    this.memberService.getMemberItems(this.queryObject).subscribe(
      (res: any) => {
        this.memberList = [];
        this.queryObject.pageCount = res.totalItems;
        console.log("Pagecount",this.queryObject.pageCount)
        this.spinningManager.hideSpinner();
        for (const item of res.items) {
          const memberItem = new Member();
          memberItem.id = item.id;
          memberItem.firstName = item.firstName;
          memberItem.lastName = item.lastName;
          memberItem.fullName = item.fullName;
          memberItem.sex = item.sex;
          memberItem.dateOfBirth = item.dateOfBirth;
          memberItem.mobile = item.mobile;
          memberItem.phone = item.phone;
          memberItem.job = item.job;
          memberItem.countryId = item.countryId;
          memberItem.countryName = item.countryName;
          memberItem.provinceId = item.provinceId;
          memberItem.provinceName = item.provinceName;
          memberItem.districtId = item.districtId;
          memberItem.districtName = item.districtName;
          memberItem.municipalitiesId = item.municipalitiesId;
          memberItem.municipalitiesName = item.municipalitiesName;
          memberItem.wardNo = item.wardNo;
          memberItem.pratinidhiSabhaId = item.pratinidhiSabhaId;
          memberItem.pratinidhiSabhaName = item.pratinidhiSabhaName;
          memberItem.pradeshSabhaId = item.pradeshSabhaId;
          memberItem.pradeshSabhaName = item.pradeshSabhaName;
          memberItem.programsId = item.programsId;
          memberItem.programsName = item.programsName;
          // memberItem.chetraId = item.chetraId;
          // memberItem.chetraName = item.chetraName;
          this.memberList.push(memberItem);
        }
      },
      (error) => {
        this.noDataFound = error;
        this.spinningManager.hideSpinner();
      }
    );
  }

  loadMember(){
    this.getMember();
  }

  onDeleteConfirm(id) {
    this.alertyfy.confirm(
      "Are you sure you want to delete?",
      () => {
        this.memberService.deleteMember(id)
          .subscribe(res => {
            this.alertyfy.success("Deleted Successfully.");
            this.getMember();
          },
            (error) => {
              this.alertyfy.error(error);
            });
      });
  }

  onCreateConfirm() {
    this._router.navigateByUrl('/managedelevery/create-member');
  }

  showHideFilter() {
    this.showFilters = !this.showFilters;
  }

  public Search() {
    this.getMember();
  }

  public Reset() {
    this.queryObject.isSortAsc = true;
    this.queryObject.sortBy = '';
    this.queryObject.searchString = '';
    this.queryObject.page = 1;
    this.queryObject.countryId = 0;
    this.queryObject.provinceId = 0;
    this.queryObject.districtId = 0;
    this.queryObject.pradeshSabhaId = 0;
    this.queryObject.pradeshSabhaId = 0;
    this.queryObject.chetraId = 0;
    this.queryObject.pageSize = this.pageSize;
    this.loadMember();
  }

  sortBy(columnName) {
    if (this.queryObject.sortBy === columnName) {
      this.queryObject.isSortAsc = !this.queryObject.isSortAsc;
    } else {
      this.queryObject.sortBy = columnName;
      this.queryObject.isSortAsc = true;
    }
    this.loadMember();
  }

  onPageChange(page) {
    this.queryObject.page = page;
    this.loadMember();
  }

  public exportToExcel(flag: boolean) {
    this.spinningManager.showSpinner('Exporting Excel.....');
    this.queryObject.printall = flag;
    this.memberService
      .exportAllItemsToExcel(this.queryObject)
      .subscribe((res: Blob) => {
        importedSaveAs(res, 'MemberList.xlsx');
        this.spinningManager.hideSpinner();
      },
      (error) => {
        this.noDataFound = error;
        this.spinningManager.hideSpinner();
      });
  }
  public exportToPdf(flag: boolean) {
    this.spinningManager.showSpinner('Exporting PDF.....');
    this.queryObject.printall = flag;
    this.memberService
      .exportAllItemsToPDF(this.queryObject)
      .subscribe((res: Blob) => {
        importedSaveAs(res, 'MemberList.pdf');
        this.spinningManager.hideSpinner();
      },
      (error) => {
        this.noDataFound = error;
        this.spinningManager.hideSpinner();
      });
  }

 public exportPDFbyId(data: any){
  this.spinningManager.showSpinner('Exporting PDF.....');
    this.memberService.exportByIdPDF(data.id).subscribe((res: Blob) =>{
      for(let i=0;i<this.memberList.length;i++){
        if(this.memberList[i].id == data.id){
          this.memberName = this.memberList[i].fullName.toLocaleUpperCase();
        }
      }
      importedSaveAs(res, `${this.memberName}.pdf`);
      this.spinningManager.hideSpinner();
    },
    (error) => {
      this.noDataFound = error;
      this.spinningManager.hideSpinner();
    });
 }

 public printBackPage(){
  this.spinningManager.showSpinner('Exporting BackPage.....');
  this.memberService.printBackPage().subscribe((res:Blob) => {
      importedSaveAs(res, 'BackPage.pdf');
      this.spinningManager.hideSpinner();
  })
 }

  getAllCountries() {
    this.countryService.getAllCountry()
      .subscribe(res => {
        this.countryList = res;
        this.spinningManager.hideSpinner();
      },
        error => {
          this.alertyfy.error(error);
          this.spinningManager.hideSpinner();
        });
  }
  getAllProvinceByCountryId(countryId) {
    this.provinceService.getProvinceByCountry(countryId).subscribe(res => {
      this.provinceList = res;
    }, error => {
    })
  }
  getProvinces() {
    this.provinceService.getAllProvince()
      .subscribe(res => {
        this.provinceList = res;
        this.spinningManager.hideSpinner();
      }, error => {
        this.spinningManager.hideSpinner();
      });
  }

  getAllDistrictsByProvinceId(provinceId) {
    this.districtService.getDistrictByProvince(provinceId)
      .subscribe(res => {
        this.districtList = res;
        this.spinningManager.hideSpinner();
      },
        error => {
          this.alertyfy.error(error);
          this.spinningManager.hideSpinner();
        });
  }

  getAllPratinidhiSabhaByDistrictId(districtId) {
    this.pratinidhiService.getPratinidhiSabhaByDistrict(districtId)
      .subscribe(res => {
        this.pratinidhiList = res;
        this.spinningManager.hideSpinner();
      },
        error => {
          this.alertyfy.error(error);
          this.spinningManager.hideSpinner();
        });
  }


  getAllPradeshSabhaByDistrictId(districtId) {
    this.pradeshService.getPradeshSabhaByDistrictId(districtId)
      .subscribe(res => {
        this.pradeshList = res;
        this.spinningManager.hideSpinner();
      },
        error => {
          this.alertyfy.error(error);
          this.spinningManager.hideSpinner();
        });
  }
}
