// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.appandentalchambers.com/api/',
  imageUrl: 'https://api.appandentalchambers.com/',
  identity_issuer: 'https://devidentity.movxl.com',
  // identity_issuer: 'https://devidentity.movxl.com',
  // identity_issuer: 'https://localhost:44310',
  identity_clientId: 'ecommerceadminapp',
  identity_logouturl: 'https://devidentity.movxl.com/Account/Logout',
  // identity_logouturl: 'https://localhost:44310/auth/logout',

  // apiUrl: 'https://localhost:5001/api/',
  // imageUrl: 'https://localhost:5001/',
  // identity_issuer: 'https://devidentity.movxl.com',
  // identity_clientId: 'ecommerceadminapp',
  // identity_logouturl: 'https://devidentity.movxl.com/Account/Logout',

  // apiUrl: 'https://api.movxl.com/api/',
  // imageUrl: 'https://api.movxl.com/',
  // identity_issuer: 'https://identity.movxl.com',
  // identity_clientId: 'ecommerceadminapp',
  // identity_logouturl: 'https://identity.movxl.com/Account/Logout',

  pageSize: 10,
  noDataFound:"No data found......."
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
