import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Member } from 'src/app/shared/models/member';
import { Program } from 'src/app/shared/models/program';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { ChetraService } from 'src/app/shared/service/ManageDelivery/chetra.service';
import { CountryService } from 'src/app/shared/service/ManageDelivery/country.service';
import { DistrictService } from 'src/app/shared/service/ManageDelivery/district.service';
import { MemberService } from 'src/app/shared/service/ManageDelivery/member.service';
import { MunicipalityService } from 'src/app/shared/service/ManageDelivery/municipality.service';
import { PradeshSabhaService } from 'src/app/shared/service/ManageDelivery/pradesh-sabha.service';
import { PratinidhiSabhaService } from 'src/app/shared/service/ManageDelivery/pratinidhi-sabha.service';
import { ProgramService } from 'src/app/shared/service/ManageDelivery/program.service';
import { ProvinceService } from 'src/app/shared/service/ManageDelivery/province.service';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-member',
  templateUrl: './create-member.component.html',
  styleUrls: ['./create-member.component.scss'],
})
export class CreateMemberComponent implements OnInit {
  memberListForm: FormGroup;
  btnSave = 'Save';
  memberid: number = 0;
  countryList: any[];
  provinceList: any[];
  districtList: any[];
  pratinidhiList: any[];
  pradeshList: any[];
  chetraList: any[];
  municipalityList: any[];
  wardList: [];
  genderList: any;
  currentCountryId: number;
  pradeshSabhaId: number;
  currentProvinceId: number;
  currentDistrictId: number;
  public response: { dbPath: '' };
  programList: [];
  formSubmitted: boolean = false;
  imageUrl: any = '';
  minDate:any;
  programsId:number = 0
  isSchool:boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private memberService: MemberService,
    private spinningManager: SpinnerManagerService,
    private programService: ProgramService,
    private router: Router,
    private alertify: AlertyfyService,
    private countryService: CountryService,
    private provinceService: ProvinceService,
    private districtService: DistrictService,
    private pratinidhiService: PratinidhiSabhaService,
    private pradeshService: PradeshSabhaService,
    private chetraService: ChetraService,
    private route: ActivatedRoute,
    private municipalityService: MunicipalityService
  ) {
    this.memberListForm = this.formBuilder.group({
      id: '',
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      sex: '',
      dateOfBirth: '',
      mobile: ['', Validators.maxLength(10)],
      phone: ['', Validators.maxLength(9)],
      job: '',
      countryId: '',
      provinceId: '',
      districtId: '',
      municipalitiesId: '',
      wardNo: '',
      pratinidhiSabhaId: '',
      pradeshSabhaId: '',
      programsId: '',
      // chetraId: '',
    });
    this.memberid =
      this.route.snapshot.params.id === undefined
        ? 0
        : parseInt(this.route.snapshot.params.id);

    // this.genderList = [{ value: 1, text: "Male", selected: "checked" }, { value: 2, text: "Female", selected: "" }];
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
  }

  ngOnInit(): void {
    // this.memberListForm.controls["sex"].setValue(2);
    const fullUrl = this.router.url;
    if(this.router.url.includes('/managedelevery/create-school')){
      this.isSchool = true;
      this.memberListForm.patchValue({'programsId':12});
    }
    if (this.memberid > 0) {
      this.editMember();
    }
    this.spinningManager.hideSpinner();

    this.getAllCountries();
    this.getAllProgram();
  }

  memberFormControl() {
    return this.memberListForm.controls;
  }

  getAllCountries() {
    this.countryService.getAllCountry().subscribe(
      (res) => {
        this.countryList = res;
        if (this.memberid == 0) {
          var countryInx = this.countryList.findIndex(
            (a) => a.countryCode === 'NP'
          );
          this.currentCountryId = this.countryList[countryInx].id;
          this.loadProvince();
          this.memberListForm.controls['countryId'].setValue(
            this.countryList ? this.countryList[countryInx].id : 0
          );
        }

        this.spinningManager.hideSpinner();
      },
      (error) => {
        this.alertify.error(error);
        this.spinningManager.hideSpinner();
      }
    );
  }

  onCountrySelection(event) {
    this.currentCountryId = Number(event.target.value);
    this.loadProvince();
  }

  loadProvince() {
    this.provinceService.getProvinceByCountry(this.currentCountryId).subscribe(
      (res) => {
        this.provinceList = res;
        if (this.memberid == 0) {
          var countryInx = this.provinceList.findIndex(
            (a) => a.provinceCode === 'MP'
          );
          this.currentProvinceId = this.provinceList[countryInx].id;
          this.memberListForm.controls['provinceId'].setValue(
            this.currentProvinceId
          );
          this.districtList = [];
          this.loadDisctrict();
        }
      },
      (error) => {}
    );
  }

  get f(){
    return this.memberListForm.controls;
  }

  onProvinceSelection(event) {
    this.currentProvinceId = Number(event.target.value);
    this.loadDisctrict();
  }

  loadDisctrict() {
    this.districtService
      .getDistrictByProvince(this.currentProvinceId)
      .subscribe(
        (res) => {
          this.districtList = res;
        },
        (error) => {}
      );
  }

  // onPratinidhiSelection(event){
  //   this.pradeshSabhaId=Number(event.target.value);
  //   this.pradeshService.getPradeshSabhaByPratinidhiId(Number(event.target.value))
  //   .subscribe(res=>{
  //     this.pradeshList=res;
  //   },
  //   error=>{
  //   });
  // }

  // getAllProvince() {
  //   this.provinceService.getAllProvince().subscribe(
  //     (res) => {
  //       this.provinceList = res;
  //       this.spinningManager.hideSpinner();
  //     },
  //     (error) => {
  //       this.alertify.error(error);
  //       this.spinningManager.hideSpinner();
  //     }
  //   );
  // }
  // getAllDistrict() {
  //   this.districtService.getAllDistrict().subscribe(
  //     (res) => {
  //       this.districtList = res;
  //       this.spinningManager.hideSpinner();
  //     },
  //     (error) => {
  //       this.alertify.error(error);
  //       this.spinningManager.hideSpinner();
  //     }
  //   );
  // }
  // getAllPratinidhi() {
  //   this.pratinidhiService.getAllPratinidhiSabha().subscribe(
  //     (res) => {
  //       this.pratinidhiList = res;
  //       this.spinningManager.hideSpinner();
  //     },
  //     (error) => {
  //       this.alertify.error(error);
  //       this.spinningManager.hideSpinner();
  //     }
  //   );
  // }

  getAllProgram() {
    this.programService.getAllPrograms().subscribe((res) => {
      this.programList = res;
    });
    this.getAllProvince();
    this.getAllMunicipalities();
    this.getAllPratinidhi();
    this.getAllPradesh();
    this.getAllDistrict();
  }

  getAllMunicipalities() {
    this.municipalityService.getAllMunicipalities().subscribe((res) => {
      this.municipalityList = res;
    });
  }
  getAllProvince() {
    this.provinceService.getAllProvince().subscribe((res) => {
      this.provinceList = res;
    });
  }

  getAllDistrict() {
    this.districtService.getAllDistrict().subscribe((res) => {
      this.districtList = res;
    });
  }

  getAllPratinidhi() {
    this.pratinidhiService.getAllPratinidhiSabha().subscribe((res) => {
      this.pratinidhiList = res;
    });
  }

  onDistrictSelection(event) {
    this.currentDistrictId = Number(event.target.value);
    this.pratinidhiService
      .getPratinidhiSabhaByDistrict(Number(event.target.value))
      .subscribe(
        (res) => {
          this.pratinidhiList = res;
        },
        (error) => {}
      );
    this.municipalityService
      .getMunicipalitiesByDistrict(Number(event.target.value))
      .subscribe(
        (res) => {
          this.municipalityList = res;
        },
        (error) => {}
      );
    this.pradeshService
      .getPradeshSabhaByDistrictId(Number(event.target.value))
      .subscribe(
        (res) => {
          this.pradeshList = res;
        },
        (error) => {}
      );
  }
  getAllPradesh() {
    this.pradeshService.getAllPradeshSabha().subscribe(
      (res) => {
        this.pradeshList = res;
        this.spinningManager.hideSpinner();
      },
      (error) => {
        this.alertify.error(error);
        this.spinningManager.hideSpinner();
      }
    );
  }

  programSelected(val){
    this.programsId = val;
  }
  
  getAllChetra() {
    this.chetraService.getAllChetra().subscribe(
      (res) => {
        this.chetraList = res;
        this.spinningManager.hideSpinner();
      },
      (error) => {
        this.alertify.error(error);
        this.spinningManager.hideSpinner();
      }
    );
  }

  editMember() {
    this.memberService.getMemberById(this.memberid).subscribe(
      (result) => {
        this.programsId = result['programsId'];
        this.spinningManager.showSpinner('Loading.....');
        this.memberListForm = this.formBuilder.group({
          firstName: result['firstName'],
          lastName: result['lastName'],
          imagePath: result['imagepath'],
          sex: result['sex'],
          mobile: result['mobile'],
          phone: result['phone'],
          dateOfBirth: result['dateOfBirth'],
          job: result['job'],
          countryId: result['countryId'],
          provinceId: result['provinceId'],
          districtId: result['districtId'],
          municipalitiesId: result['municipalitiesId'],
          wardNo: result['wardNo'],
          pratinidhiSabhaId: result['pratinidhiSabhaId'],
          pradeshSabhaId: result['pradeshSabhaId'],
          programsId: result['programsId'],
          // chetraId: result['chetraId'],
        });

        this.imageUrl = environment.imageUrl + result['imagepath'];
        this.btnSave = 'Update';
      },
      (error) => {
        this.alertify.error(error.error);
        if(this.isSchool)this.router.navigateByUrl('/managedelevery/school-list');
        else this.router.navigateByUrl('/managedelevery/member-list');
      }
    );
    this.spinningManager.hideSpinner();
  }

  saveMemberList() {
    if (this.memberid && this.memberid > 0) {
      const memberupdatedata = {
        id: this.memberid,
        firstName: this.memberListForm.controls.firstName.value,
        lastName: this.memberListForm.controls.lastName.value,
        imagepath: this.response?.dbPath,
        sex: this.memberListForm.controls.sex.value,
        dateOfBirth: this.memberListForm.controls.dateOfBirth.value,
        mobile: this.memberListForm.controls.mobile.value,
        phone: this.memberListForm.controls.phone.value,
        job: this.memberListForm.controls.job.value,
        countryId: this.memberListForm.controls.countryId.value,
        provinceId: this.memberListForm.controls.provinceId.value,
        districtId: this.memberListForm.controls.districtId.value,
        pratinidhiSabhaId: this.memberListForm.controls.pratinidhiSabhaId.value,
        pradeshSabhaId: this.memberListForm.controls.pradeshSabhaId.value,
        // chetraId: this.memberListForm.controls.chetraId.value,
        municipalitiesId: this.memberListForm.controls.municipalitiesId.value,
        wardNo: this.memberListForm.controls.wardNo.value,
        programsId: this.memberListForm.controls.programsId.value,
      };
      this.memberService.upsertMember(memberupdatedata).subscribe(
        (result) => {
          this.spinningManager.showSpinner('Saving.....');
          if(this.isSchool)this.router.navigateByUrl('/managedelevery/school-list');
          else this.router.navigateByUrl('/managedelevery/member-list');
          this.spinningManager.hideSpinner();
        },
        (error) => {
          this.spinningManager.hideSpinner();
        }
      );
    } else {
      if (this.memberListForm.valid) {
        var _member = new Member();
        var member = this.memberListForm.value;
        _member.id = Number(member.id) || 0;
        _member.firstName = member.firstName;
        _member.lastName = member.lastName;
        _member.sex = member.sex;
        _member.dateOfBirth = member.dateOfBirth;
        _member.mobile = member.mobile;
        _member.phone = member.phone;
        _member.job = member.job;
        _member.countryId = Number(member.countryId);
        _member.provinceId = Number(member.provinceId);
        _member.districtId = Number(member.districtId);
        _member.pratinidhiSabhaId = Number(member.pratinidhiSabhaId);
        _member.pradeshSabhaId = Number(member.pradeshSabhaId);
        // _member.chetraId = Number(member.chetraId);
        _member.municipalitiesId = Number(member.municipalitiesId);
        _member.wardNo = member.wardNo;
        _member.imagepath = this.response?.dbPath;
        _member.programsId = Number(member.programsId);
        this.memberService.createMember(_member).subscribe(
          (res) => {
            if (res) {
              this.memberListForm.reset();
              if(this.isSchool)this.router.navigateByUrl('/managedelevery/school-list');
              else this.router.navigateByUrl('/managedelevery/member-list');
              this.spinningManager.hideSpinner();
            }
          },
          (error) => {
            this.alertify.error(error);
            this.spinningManager.hideSpinner();
          }
        );
      } else {
        this.formSubmitted = true;
      }
    }
    (error) => {
      this.spinningManager.hideSpinner();
      if(this.isSchool)this.router.navigateByUrl('/managedelevery/school-list');
      else this.router.navigateByUrl('/managedelevery/member-list');
    };
  }

  public uploadFinished = (event) => {
    this.response = event;
  };

  public createImgPath = (serverPath: string) => {
    return `${environment.imageUrl} ${serverPath}`;
  };
}
