import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CityListComponent } from './city-list/city-list.component';
import { CityCreateComponent } from './city-create/city-create.component';
import { CreateCountryComponent } from './create-country/create-country.component';
import { CountryListComponent } from './country-list/country-list.component';
import { AddRegionComponent } from './add-region/add-region.component'; //import { RegionListComponent } from './region-list/region-list.component';
import { AddDistrictComponent } from './add-district/add-district.component';
import { DistrictListComponent } from './district-list/district-list.component';
import { RegionListComponent } from './region-list/region-list.component';
import { AddProvinceComponent } from './add-province/add-province.component';
import { ProvinceListComponent } from './province-list/province-list.component';
import { AddMunicipalityComponent } from './add-municipality/add-municipality.component';
import { MunicipalityListComponent } from './municipality-list/municipality-list.component';
import { AuthGuard } from 'src/app/shared/core/AuthGaurd';
import { DataPermissions } from 'src/app/shared/enums/DataPermissions';
import { systemPermissions } from 'src/app/shared/enums/systemPermissions';
import { MemberListComponent } from './member-list/member-list.component';
import { CreateMemberComponent } from './create-member/create-member.component';
import { PratinidhiSabhaListComponent } from './pratinidhi-sabha-list/pratinidhi-sabha-list.component';
import { PradeshSabhaListComponent } from './pradesh-sabha-list/pradesh-sabha-list.component';
import { ChetraListComponent } from './chetra-list/chetra-list.component';
import { AddPratinidhiSabhaComponent } from './add-pratinidhi-sabha/add-pratinidhi-sabha.component';
import { AddPradeshSabhaComponent } from './add-pradesh-sabha/add-pradesh-sabha.component';
import { AddChetraComponent } from './add-chetra/add-chetra.component';
import { ProgramComponent } from './program/program.component';
import { AddProgramComponent } from './add-program/add-program.component';
import { DoctorListComponent } from './doctor-list/doctor-list.component';
import { CreateDoctorComponent } from './create-doctor/create-doctor.component';
import { ListRegistedBranchComponent } from './RegistedBranch/list-registed-branch/list-registed-branch.component';
import { UpsertRegistedBranchComponent } from './RegistedBranch/upsert-registed-branch/upsert-registed-branch.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'create-country',
        component: CreateCountryComponent,
        canActivate: [AuthGuard],
        data: {
          // datapermissionIds: [DataPermissions.Countries_Add],
          title: 'Create Country',
          breadcrumb: 'Create Country',
        },
      },
      {
        path: 'create-country/:id',
        component: CreateCountryComponent,
        canActivate: [AuthGuard],
        data: {
          // datapermissionIds: [DataPermissions.Countries_Edit],
        },
      },
      {
        path: 'add-province',
        component: AddProvinceComponent,
        data: {
          title: 'add-province',
          breadcrumb: 'Add Province',
        },
      },
      {
        path: 'add-province/:id',
        component: AddProvinceComponent,
      },
      {
        path: 'add-district',
        component: AddDistrictComponent,
        data: {
          title: 'add-district',
          breadcrumb: 'Add District',
        },
      },
      {
        path: 'add-district/:id',
        component: AddDistrictComponent,
      },
      {
        path: 'city-create',
        component: CityCreateComponent,
        data: {
          title: 'City Create',
          breadcrumb: 'City Create',
        },
      },
      {
        path: 'city-create/:id',
        component: CityCreateComponent,
      },
      // {
      //   path: 'add-region',
      //   component: AddRegionComponent,
      //   data: {
      //     title: 'add-region',
      //     breadcrumb: 'Add Region',
      //   },
      // },
      // {
      //   path: 'add-region/:id',
      //   component: AddRegionComponent,
      // },
      {
        path: 'add-municipality',
        component: AddMunicipalityComponent,
        data: {
          title: 'add-municipality',
          breadcrumb: 'Add Municipality',
        },
      },
      {
        path: 'add-municipality/:id',
        component: AddMunicipalityComponent,
      },
      {
        path: 'add-program/:id',
        component: AddProgramComponent,
      },

      {
        path: 'country-list',
        component: CountryListComponent,
        canActivate: [AuthGuard],
        data: {
          // systemPermissionIds: [systemPermissions.Countries],
          title: 'Country List',
          breadcrumb: 'Country List',
        },
      },
      {
        path: 'province-list',
        component: ProvinceListComponent,
        canActivate: [AuthGuard],
        data: {
          // systemPermissionIds: [systemPermissions.Provinces],
          title: 'province-list',
          breadcrumb: 'Province List',
        },
      },
      {
        path: 'district-list',
        component: DistrictListComponent,
        canActivate: [AuthGuard],
        data: {
          // systemPermissionIds: [systemPermissions.Districts],
          title: 'district-list',
          breadcrumb: 'District List',
        },
      },
      {
        path: 'city-list',
        component: CityListComponent,
        canActivate: [AuthGuard],
        data: {
          // systemPermissionIds: [systemPermissions.Cities],
          title: 'City List',
          breadcrumb: 'City List',
        },
      },
      // {
      //   path: 'region-list',
      //   component: RegionListComponent,
      //   canActivate: [AuthGuard],
      //   data: {
      //     systemPermissionIds: [systemPermissions.Regions],
      //     title: 'region-list',
      //     breadcrumb: 'Region List',
      //   },
      // },
      {
        path: 'municipality-list',
        component: MunicipalityListComponent,
        canActivate: [AuthGuard],
        data: {
          // systemPermissionIds: [systemPermissions.Muncipalites],
          title: 'municipality-list',
          breadcrumb: 'Municipality List',
        },
      },
      {
        path: 'pratinidhi-sabha-list',
        component: PratinidhiSabhaListComponent,
        canActivate: [AuthGuard],
        data: {
          // systemPermissionIds: [systemPermissions.Muncipalites],
          title: 'pratinidhi-sabha-list',
          breadcrumb: 'Pratinidhi Sabha List',
        },
      },
      {
        path: 'pradesh-sabha-list',
        component: PradeshSabhaListComponent,
        canActivate: [AuthGuard],
        data: {
          // systemPermissionIds: [systemPermissions.Muncipalites],
          title: 'pradesh-sabha-list',
          breadcrumb: 'Pradesh Sabha List',
        },
      },
      {
        path: 'chetra-list',
        component: ChetraListComponent,
        canActivate: [AuthGuard],
        data: {
          // systemPermissionIds: [systemPermissions.Muncipalites],
          title: 'chetra-list',
          breadcrumb: 'Chetra List',
        },
      },
      {
        path: 'program-list',
        component: ProgramComponent,
        canActivate: [AuthGuard],
        data: {
          // systemPermissionIds: [systemPermissions.Muncipalites],
          title: 'program-list',
          breadcrumb: 'Program List',
        },
      },
      {
        path: 'add-program',
        component: AddProgramComponent,
        data: {
          title: 'add-program',
          breadcrumb: 'Add Program',
        },
      },
      {
        path: 'add-pratinidhi-sabha',
        component: AddPratinidhiSabhaComponent,
        data: {
          title: 'add-pratinidhi-sabha',
          breadcrumb: 'Add Pratinidhi Sabha',
        },
      },
      {
        path: 'add-pratinidhi-sabha/:id',
        component: AddPratinidhiSabhaComponent,
        data: {
          title: 'update-pratinidhi-sabha',
          breadcrumb: 'Update Pratinidhi Sabha',
        },
      },
      {
        path: 'add-pradesh-sabha',
        component: AddPradeshSabhaComponent,
        data: {
          title: 'add-pradesh-sabha',
          breadcrumb: 'Add Pradesh Sabha',
        },
      },
      {
        path: 'add-pradesh-sabha/:id',
        component: AddPradeshSabhaComponent,
        data: {
          title: 'update-pradesh-sabha',
          breadcrumb: 'Update Pradesh Sabha',
        },
      },
      {
        path: 'add-chetra',
        component: AddChetraComponent,
        data: {
          title: 'add-chetra',
          breadcrumb: 'Add Chetra',
        },
      },
      {
        path: 'add-chetra/:id',
        component: AddChetraComponent,
        data: {
          title: 'update-chetra',
          breadcrumb: 'Update Chetra',
        },
      },
    ],
  },
  {
    path: 'member-list',
    component: MemberListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'member-list',
      breadcrumb: 'Member List',
    },
  },
  {
    path: 'school-list',
    component: MemberListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'school-list',
      breadcrumb: 'School List',
    },
  },
  {
    path: 'create-member',
    component: CreateMemberComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'create-member',
      breadcrumb: 'Create Member',
    },
  },
  {
    path: 'create-school',
    component: CreateMemberComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'create-member',
      breadcrumb: 'Create Member',
    },
  },
  {
    path: 'create-school/:id',
    component: CreateMemberComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'update-member',
      breadcrumb: 'Update Member',
    },
  },
  {
    path: 'create-member/:id',
    component: CreateMemberComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'update-member',
      breadcrumb: 'Update Member',
    },
  },
  {
    path: 'doctor-list',
    component: DoctorListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'doctor-list',
      breadcrumb: 'Doctor List',
    },
  },
  {
    path: 'create-doctor',
    component: CreateDoctorComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'create-doctor',
      breadcrumb: 'Create Doctor',
    },
  },
  {
    path: 'create-doctor/:id',
    component: CreateDoctorComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'update-doctor',
      breadcrumb: 'Update doctor',
    },
  },
  {
    path: 'registed-branch-list',
    component: ListRegistedBranchComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Registed Branch',
      breadcrumb: 'Registed Branch',
    },
  },
  {
    path: 'create-registed-branch',
    component: UpsertRegistedBranchComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Create-Registed-Branch',
      breadcrumb: 'Create Registed Branch',
    },
  },
  {
    path: 'edit-registed-branch/:id',
    component: UpsertRegistedBranchComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Edit-Registed-Branch',
      breadcrumb: 'Update Registed Branch',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManagedeleveryRoutingModule {}
