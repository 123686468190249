<div class="container-fluid">
    <div class="row">
      <div class="col-lg-12"></div>
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row width-half">
              <div class="col-md-6 col-sm-7">
                <h5>
                  Doctor List
                  <button type="button" class="btn btn-success ml-2" (click)="onCreateConfirm()">
                    Add New <i class="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </h5>
              </div>
  
              <div class="col-md-6 col-sm-5">
                <a href="javascript:void(0)" (click)="showHideFilter()" class="filter">
                  <i class="fa fa-filter" aria-hidden="true"></i> Filter
                </a>
              </div>
            </div>
          </div>
          <div class="card-body margin-sm no-margs">
            <div class="row border border-light rounded p-3 mb-3 shadow-sm" *ngIf="showFilters">
  
  
              <div class="col-md-6 col-sm-12">
                <label for="search">Search</label>
                <input class="form-control" placeholder="Search Text" id="search" type="text" required=""
                  [(ngModel)]="queryObject.searchString" (keyup.enter)="Search()" />
              </div>
              <div class="col-md-6 col-sm-12">
                <label for="pradeshSabhaId">Pradesh Sabha</label>
                <select class="custom-select form-control" id="pradeshSabhaId" [(ngModel)]="queryObject.pradeshSabhaId">
                  <option [value]="0">PradeshSabha</option>
                  <option *ngFor="let obj of pradeshList; let i = index" [value]="obj.id">
                    {{ obj.name }}
                  </option>
                </select>
              </div>
              
             
              <div class="col-md-6 col-sm-12">

                <label for="countryId"> Registed Branch</label>
                <select  placeholder="Select a Registed Branch"
                  class="custom-select form-control " [(ngModel)]="queryObject.registedOnBranchId"
                  id="registedOnBranchId">
                  <option value='0' >Select Registed Branch</option>
                  <option *ngFor="let registedOnBranch of registedBranchList; let i = index"
                    [value]="registedOnBranch.id">
                    {{ registedOnBranch.name }}
                  </option>
                </select>
                
              </div>
            
             
              <!-- <div class="col-md-6 col-sm-12">
                <select class="form-control" id="pratinidhiSabhaId" [(ngModel)]="queryObject.pratinidhiSabhaId">
                  <option [value]="0">--PratinidhiSabha--</option>
                  <option *ngFor="let obj of pratinidhiList; let i = index" [value]="obj.id">
                    {{ obj.name }}
                  </option>
                </select>
              </div> -->
                <div class="col-md-6 col-sm-12">
                  <label for="qualification"> Qualification</label>
                  <select placeholder="Select a Program" class="custom-select form-control "
                  [(ngModel)]="queryObject.qualification" id="qualification">
                    <option value="" selected>Select Qualification</option>
                    <option value="MBBS">MBBS</option>
                    <option value="MD">MD</option>
                    <option value="BDS">BDS</option>
                    <option value="DDM">DDM</option>
                    <option value="DDS">DDS</option>
                  </select>
                </div>
                <div class="col-md-6">

                </div>
                <div class="col-md-6 col-sm-12 d-flex align-items-center mt-4 justify-content-end">
                 
                 <div >
                  <button type="button" class="btn btn-primary" (click)="Reset()">
                    Reset
                  </button>
                 </div>
                 <div class="ml-3">
                  <button type="button" class="btn btn-success " (click)="Search()">
                    Search
                  </button>
                 </div>
                </div>
              </div>
  
          
           
  
          
            <div class="row export-btn">
              <div class="col-12">
                <div class="btn-group btn-group1 mr-1">
                  <button type="button" class="btn btn-secondary btn1">
                    Export to Excel
                  </button>
                  <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                    <button class="btn btn-secondary dropdown-toggle-split" ngbDropdownToggle></button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                      <button ngbDropdownItem (click)="exportToExcel(false)">
                        Current Page
                      </button>
                      <button ngbDropdownItem (click)="exportToExcel(true)">
                        All Page
                      </button>
                    </div>
                  </div>
                </div>
                <div class="btn-group btn-group1">
                  <button type="button" class="btn btn-secondary btn1">
                    Export to PDF
                  </button>
                  <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                    <button class="btn btn-secondary dropdown-toggle-split" ngbDropdownToggle></button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                      <button ngbDropdownItem (click)="exportToPdf(false)">
                        Current Page
                      </button>
                      <button ngbDropdownItem (click)="exportToPdf(true)">
                        All Page
                      </button>
                      <button ngbDropdownItem (click)="printBackPage()">
                        Print Back Page
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table mt-3 text-nowrap table-bordered">
                <thead>
                  <tr>
                    <th *ngFor="let c of columns">
                      <div *ngIf="c.isSortable" (click)="sortBy(c.key)">
                        {{ c.title }}
                        <i *ngIf="queryObject.sortBy === c.key" class="fa" [class.fa-sort-asc]="queryObject.isSortAsc"
                          [class.fa-sort-desc]="!queryObject.isSortAsc"></i>
                      </div>
                      <div *ngIf="!c.isSortable">
                        {{ c.title }}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody *ngIf="doctorList?.length; else no_data_temp">
                  <tr *ngFor="let item of doctorList">
                    <td class="edit-tableicon ">
                      <a [routerLink]="['/managedelevery/create-doctor', item.id]">
                        <i class="fa fa-edit" ></i>
                      </a>
  
                      <a class="cursor-pointer" >
                        <i (click)="onDeleteConfirm(item.id)" class="fa fa-trash cursor-pointer">
                        </i>
                      </a>
                      <a class="cursor-pointer" >
                        <i (click)="exportPDFbyId(item,item.firstName,item.lastName)" class="fa fa-file-pdf-o cursor-pointer">
                        </i>
                      </a>
                      <!-- <a class="cursor-pointer" >
                        <i (click)="ExportToJpg(item,item.firstName,item.lastName)" class="fa fa-file-pdf-o cursor-pointer">
                        </i>
                      </a> -->
                    </td>
                    <td>
                      {{ item.id }} 
                    </td>
                    <td>
                      {{ item.firstName }} {{item.lastName}}
                    </td>
                    <td>
                      {{ item.sex }}
                    </td>
                    <td>
                      {{ item.dateOfBirth | date }}
                    </td>
                    <td>
                      {{ item.mobile }}
                    </td>
                    <td>
                      {{ item.phone }}
                    </td>
                    <td>
                      {{ item.job }}
                    </td>
                    <td>
                      {{ item.countryName }}
                    </td>
                    <td>
                      {{ item.provinceName }}
                    </td>
                    <td>
                      {{ item.districtName }}
                    </td>
                    <td>
                      {{ item.municipalitiesName }}
                    </td>
                    <td>
                      {{ item.pratinidhiSabhaName }}
                    </td>
                    <td>
                      {{ item.pradeshSabhaName }}
                    </td>
                    <td>
                      {{ item.wardNo }}
                    </td>
                    <td>
                      {{ item.nmcNumber }}
                    </td>
                    <td>
                      {{ item.nmcRegistrationDate }}
                    </td>
                    <td>
                      {{ item.isPermanent }}
                    </td>
                    <td>
                      {{ item.designation }}
                    </td>
                    <td>
                      {{ item.totalExperience }}
                    </td>
                    <td>
                      {{ item.currentHospitalAddress }}
                    </td>
                    <td>
                      {{ item.currentHospitalContactNo }}
                    </td>
                    <td>
                      {{ item.isPrivateHospital }}
                    </td>
                    <td>
                      {{ item.qualification }}
                    </td>
                    <td>
                      {{ item.affiliatedUniversity }}
                    </td>
                    <td>
                      {{ item.collegeName }}
                    </td>
                    <!-- <td>
                      {{ item.countryName}}
                    </td> -->
                    <td>
                      {{ item.passedDate }}
                    </td>
                    <td>
                      {{ item.financialScheme }}
                    </td>
                    <td>
                      {{ item.email }}
                    </td>
                    <td>
                      {{ item.bloodGroup }}
                    </td>
                    <td>
                      {{ item.ndaNumber }}
                    </td>
                  </tr>
                </tbody>
                <ng-template #no_data_temp>
                  <tbody>
                    <td colspan="14">
                      <h4 class="text-center d-block w-100 mt-3">
                        {{ this.noDataFound }}
                      </h4>
                    </td>
                  </tbody>
                </ng-template>
              </table>
  
              <ngb-pagination [(page)]="queryObject.page"  [maxSize]="queryObject.pageSize" [pageSize]="queryObject.pageSize" [collectionSize]="queryObject.pageCount" 
                  (pageChange)="onPageChange($event)" [rotate]="true" [boundaryLinks]="true"
                   aria-label="Default pagination">
              </ngb-pagination>
            </div>
  
  
          </div>
        </div>
      </div>
    </div>
  </div>
  